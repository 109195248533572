import React from 'react';
import LeftMenu from './LeftMenu';

function App() {
  return (
    <div className="App">
      <LeftMenu />
    </div>
  );
}

export default App;