import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChartLine, FaChevronDown, FaRocket, FaDollarSign, FaFileContract, FaUserCircle, FaComments, FaCalendar, FaAddressBook, FaHandshake, FaCreditCard, FaEnvelope, FaCogs, FaGlobe, FaGraduationCap, FaImage, FaStar, FaChartBar, FaThLarge, FaMobileAlt, FaClipboardList, FaHome, FaFileAlt, FaBuilding, FaUsers, FaRobot, FaPhone, FaWhatsapp, FaList, FaRandom, FaPuzzlePiece, FaTags, FaFlask, FaHistory, FaIndustry } from 'react-icons/fa';

const LeftMenu = () => {
  const [isCRMOpen, setIsCRMOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.locationId) {
        setLocationId(event.data.locationId);
      } else if (event.data && event.data.type === 'ACTIVE_PAGE') {
        const activePath = event.data.path;
        console.log('Received active page:', activePath);
        
        const crmPath = crmItems.find(item => activePath.includes(item.link));
        const settingsPath = settingsItems.find(item => item.link && activePath.includes(item.link));
        const menuPath = menuItems.find(item => activePath.includes(item.id));
        
        if (crmPath) {
          setIsCRMOpen(true);
          setActiveSection(activePath);
        } else if (settingsPath) {
          setIsSettingsOpen(true);
          setActiveSection(activePath);
        } else if (menuPath) {
          setActiveSection(menuPath.id);
        }
      } else if (event.data.type === 'LOCATION_ID') {
        setLocationId(event.data.locationId);
      }
    };

    window.addEventListener('message', handleMessage);

    // Request the locationId from the parent window
    window.parent.postMessage({ type: 'GET_LOCATION_ID' }, '*');

    // Request the active page from the parent window
    window.parent.postMessage({ type: 'GET_ACTIVE_PAGE' }, '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const checkCurrentPath = () => {
      const path = window.location.pathname;
      const crmPath = crmItems.find(item => path.includes(item.link));
      const settingsPath = settingsItems.find(item => item.link && path.includes(item.link));
      
      if (crmPath) {
        setIsCRMOpen(true);
        setActiveSection(path); // Set the full path as active section
      } else if (settingsPath) {
        setIsSettingsOpen(true);
        setActiveSection(path); // Set the full path as active section
      } else {
        const menuItem = menuItems.find(item => path.includes(item.id));
        if (menuItem) {
          setActiveSection(path); // Set the full path as active section
        }
      }
    };

    checkCurrentPath();
    window.addEventListener('popstate', checkCurrentPath);

    return () => {
      window.removeEventListener('popstate', checkCurrentPath);
    };
  }, []);

  const menuItems = [
    { icon: <FaChartLine />, label: 'Offer Dashboard', id: '6e08ab6e-439d-4e34-bac5-389771b6fdcc' },
    { icon: <FaDollarSign />, label: 'Cash Offers', id: '5fd819c1-f446-472e-8fa7-fcdb2842f9ab' },
    { icon: <FaFileContract />, label: 'Subject To Offers', id: '7072650f-0278-4829-9ad1-56472aebc49c' },
  ];

  const crmItems = [
    { icon: <FaChartLine />, label: 'Dashboard', link: 'dashboard' },
    { icon: <FaComments />, label: 'Conversations', link: 'conversations/conversations' },
    { icon: <FaCalendar />, label: 'Calendars', link: 'calendars/view' },
    { icon: <FaAddressBook />, label: 'Contacts', link: 'contacts/smart_list/All' },
    { icon: <FaHandshake />, label: 'Opportunities', link: 'opportunities/list' },
    { icon: <FaCreditCard />, label: 'Payments', link: 'payments/invoices' },
    { icon: <FaEnvelope />, label: 'Marketing', link: 'marketing/social-planner/' },
    { icon: <FaCogs />, label: 'Automation', link: 'automation/workflows' },
    { icon: <FaGlobe />, label: 'Sites', link: 'funnels-websites/funnels' },
    { icon: <FaImage />, label: 'Media Storage', link: 'media-storage' },
    { icon: <FaStar />, label: 'Reputation', link: 'reputation/overview' },
    { icon: <FaChartBar />, label: 'Reporting', link: 'reporting/google-ads' },
    { icon: <FaThLarge />, label: 'App Marketplace', link: 'integration' },
  ];

  const settingsItems = [
    { label: 'MY BUSINESS', isHeader: true },
    { icon: <FaBuilding />, label: 'Business Profile', link: 'settings/company' },
    { icon: <FaUserCircle />, label: 'My Profile', link: 'settings/profile' },
    { icon: <FaCreditCard />, label: 'Company Billing', link: 'settings/company-billing/billing' },
    { icon: <FaUsers />, label: 'My Staff', link: 'settings/staff/team' },
    { icon: <FaChartLine />, label: 'Opportunities & Pipelines', link: 'crm-settings' },
    { label: 'BUSINESS SERVICES', isHeader: true },
    { icon: <FaCogs />, label: 'Automation', link: 'settings/automation/' },
    { icon: <FaCalendar />, label: 'Calendars', link: 'settings/calendars/' },
    { icon: <FaRobot />, label: 'Conversation AI', link: 'settings/conversation-ai' },
    { icon: <FaEnvelope />, label: 'Email Services', link: 'settings/smtp_service' },
    { icon: <FaPhone />, label: 'Phone Numbers', link: 'settings/phone_number' },
    { icon: <FaWhatsapp />, label: 'WhatsApp', link: 'settings/whatsapp' },
    { icon: <FaStar />, label: 'Reputation Management', link: 'settings/reputation/settings' },
    { label: 'OTHER SETTINGS', isHeader: true },
    { icon: <FaClipboardList />, label: 'Custom Fields', link: 'settings/fields' },
    { icon: <FaList />, label: 'Custom Values', link: 'settings/custom_values' },
    { icon: <FaChartBar />, label: 'Manage Scoring', link: 'settings/scoring' },
    { icon: <FaGlobe />, label: 'Domains', link: 'settings/domain' },
    { icon: <FaRandom />, label: 'URL Redirects', link: 'settings/redirect' },
    { icon: <FaPuzzlePiece />, label: 'Integrations', link: 'settings/integrations/list' },
    { icon: <FaComments />, label: 'Conversation Providers', link: 'settings/conversation_providers' },
    { icon: <FaTags />, label: 'Tags', link: 'settings/tags' },
    { icon: <FaFlask />, label: 'Labs', link: 'settings/labs', isNew: true },
    { icon: <FaHistory />, label: 'Audit Logs', link: 'settings/audit/logs' },
    { icon: <FaIndustry />, label: 'Companies', link: 'settings/businesses' },
  ];

  const handleLinkClick = (link, isMenuItem = false, isSettingsItem = false) => {
    let fullLink;
    let isCustomMenuLink = false;

    if (isMenuItem) {
      fullLink = link; // For menu items, use the ID directly
      isCustomMenuLink = true;
    } else if (locationId) {
      fullLink = link;
    } else {
      console.error('LocationId not available');
      return;
    }

    console.log('Sending navigation message:', fullLink);
    window.parent.postMessage({ 
      type: 'NAVIGATE', 
      link: fullLink,
      locationId: locationId,
      isSettingsItem: isSettingsItem,
      isCustomMenuLink: isCustomMenuLink
    }, '*');
    setActiveSection(fullLink); // Set the full link as the active section
  };

  return (
    <nav className="bg-white text-gray-800 w-56 min-h-screen p-4 font-sans shadow-lg">
      <div className="mb-8">
        <img src="https://msgsndr-private.storage.googleapis.com/companyPhotos/2e1e54c2-dd0c-483f-87cd-6c824cf212b4.png" alt="OctaTech Logo" className="w-40 mx-auto" />
      </div>
      
      <ul className="space-y-2">
        {menuItems.map((item, index) => (
          <li key={index}>
            <a
              href="#"
              onClick={() => handleLinkClick(item.id, true)}
              className={`flex items-center space-x-3 p-2 rounded-lg transition-colors duration-200 hover:bg-gray-100 ${
                activeSection === item.id ? 'bg-gray-100' : ''
              }`}
            >
              <span className="text-blue-600">{item.icon}</span>
              <span className="font-medium">{item.label}</span>
            </a>
          </li>
        ))}
      </ul>

      <div className="mt-8">
        <button
          onClick={() => setIsCRMOpen(!isCRMOpen)}
          className="flex items-center justify-between w-full p-2 rounded-lg transition-colors duration-200 hover:bg-gray-100"
        >
          <div className="flex items-center space-x-3">
            <FaAddressBook className="text-blue-600" />
            <span className="font-medium">CRM</span>
          </div>
          <motion.div
            animate={{ rotate: isCRMOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <FaChevronDown className="text-gray-600" />
          </motion.div>
        </button>

        <AnimatePresence>
          {isCRMOpen && (
            <motion.ul
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-2 space-y-2 pl-4"
            >
              {crmItems.map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <a
                    href="#"
                    onClick={() => handleLinkClick(item.link)}
                    className={`flex items-center space-x-2 py-2 px-3 rounded-md transition-colors duration-200 hover:bg-gray-100 text-gray-700 ${
                      activeSection && activeSection.includes(item.link) ? 'bg-gray-100' : ''
                    }`}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </a>
                </motion.li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>

      <div className="mt-8">
        <button
          onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          className="flex items-center justify-between w-full p-2 rounded-lg transition-colors duration-200 hover:bg-gray-100"
        >
          <div className="flex items-center space-x-3">
            <FaCogs className="text-blue-600" />
            <span className="font-medium">Settings</span>
          </div>
          <motion.div
            animate={{ rotate: isSettingsOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <FaChevronDown className="text-gray-600" />
          </motion.div>
        </button>

        <AnimatePresence>
          {isSettingsOpen && (
            <motion.ul
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-2 space-y-2 pl-4"
            >
              {settingsItems.map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: index * 0.05 }}
                >
                  {item.isHeader ? (
                    <p className="text-xs text-gray-500 uppercase mt-4 mb-2">{item.label}</p>
                  ) : (
                    <a
                      href="#"
                      onClick={() => handleLinkClick(item.link, false, true)} // Added isSettingsItem flag
                      className={`flex items-center space-x-2 py-2 px-3 rounded-md transition-colors duration-200 hover:bg-gray-100 text-gray-700 ${
                        activeSection && activeSection.includes(item.link) ? 'bg-gray-100' : ''
                      }`}
                    >
                      {item.icon}
                      <span>{item.label}</span>
                      {item.isNew && <span className="text-xs bg-blue-500 text-white px-1 rounded ml-2">New</span>}
                    </a>
                  )}
                </motion.li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default LeftMenu;